import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user";
import screenReducer from "./screens";
import memberReducer from "./members";
import customerReducer from "./customer";
import transactionHistoryReducer from "./transactionhistory";

export default configureStore({
  reducer: {
    user: userReducer,
    screens: screenReducer,
    members: memberReducer,
    customer: customerReducer,
    transactionhistory: transactionHistoryReducer,
  },
});
