import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerMobile, setCustomerMobile } from "../redux/customer";
import { resetDefaultScreenDetails, setScreenDetails } from "../redux/screens";
import { findMemberByPhone } from "../services/members";
import { SCREEN_ADD_POINTS, SCREEN_NOT_FOUND } from "../utils/const";

function AddPointsSearch() {
  const dispatch = useDispatch();
  const mobile = useSelector(getCustomerMobile);

  const searchHandler = () => {
    const item = `+63${mobile}`;
    findMemberByPhone(item).then((data) => {
      const [found] = data;
      if (found) {
        dispatch(setScreenDetails({ type: SCREEN_ADD_POINTS, data: found }));
      } else {
        dispatch(setScreenDetails({ type: SCREEN_NOT_FOUND, data: item }));
      }
    });
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex flex-column vertical-center justify-content-center">
            <div>
              <h1 className="text-danger fw-bold pb-4">Add Points</h1>
            </div>
            <div>
              <h2>What's the phone number?</h2>
            </div>
            <div className="input-group my-4">
              <span className="input-group-text" id="country-code">
                +63
              </span>
              <input
                value={mobile}
                inputMode="numeric"
                onChange={({ currentTarget: input }) => {
                  if (!isNaN(input.value)) {
                    dispatch(setCustomerMobile(input.value));
                  }
                }}
                type="text"
                className="form-control form-control-lg"
                placeholder=""
                aria-label="Phone Number"
                aria-describedby="country-code"
              />
            </div>
            <div className="text-center">
              <Button
                variant="outline-secondary"
                size="lg"
                className="me-4"
                onClick={() => dispatch(resetDefaultScreenDetails())}
              >
                Home
              </Button>
              <Button
                variant="danger"
                size="lg"
                className="px-4"
                disabled={mobile.length < 10}
                onClick={searchHandler}
              >
                Continue
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddPointsSearch;
