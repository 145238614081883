import React from "react";

const Input = ({
  name,
  label,
  error,
  showsuccess,
  disabled = false,
  ...rest
}) => {
  let formClass = "form-control form-control-lg ";
  if (error) {
    formClass = `${formClass} is-invalid`;
  }
  if (showsuccess) {
    formClass = `${formClass} is-valid`;
  }
  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}

      <input
        {...rest}
        name={name}
        id={name}
        className={formClass}
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        disabled={disabled}
      />
      {error && (
        <small style={{ marginTop: "5px" }} className="text-danger">
          {error}
        </small>
      )}
    </div>
  );
};

export default Input;
