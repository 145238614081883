import React from "react";
import FollowTheSignsRoundedIcon from "@mui/icons-material/FollowTheSignsRounded";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const history = useNavigate();
  return (
    <Container className="mx-auto">
      <Row>
        <Col>
          <div className="d-flex flex-column vertical-center justify-content-center">
            <FollowTheSignsRoundedIcon className="largeicon text-danger pb-4" />
            <Button variant="danger" size="lg" onClick={() => history("/")}>
              Back to Home
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound;
