import React from "react";
import { Container, Navbar } from "react-bootstrap";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import { useSelector } from "react-redux";
import { getUserDetails } from "../redux/user";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import { getAuth, signOut } from "firebase/auth";

function Header() {
  const user = useSelector(getUserDetails);
  return (
    <Navbar bg="light" fixed="top">
      <Container>
        <Navbar.Brand href="" className="text-danger fw-bold">
          <CardMembershipIcon /> Member Kard
        </Navbar.Brand>

        {user && (
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <div
                className="text-dark pointer"
                onClick={async () => {
                  const auth = getAuth();
                  await signOut(auth);
                }}
              >
                <ExitToAppRoundedIcon /> Logout
              </div>
            </Navbar.Text>
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
}

export default Header;
