import React from "react";
import { useDispatch } from "react-redux";
import { resetDefaultScreenDetails } from "../redux/screens";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

function HomeButton() {
  const dispatch = useDispatch();
  return (
    <div
      className="fs-5 d-flex justify-content-start align-items-center pt-2"
      onClick={() => dispatch(resetDefaultScreenDetails())}
    >
      <HomeOutlinedIcon className="text-danger" />
      <div className="mx-2">Home</div>
    </div>
  );
}

export default HomeButton;
