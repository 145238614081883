import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import MenuShortcuts from "../common/MenuShortcuts";
import { getCustomerMobile, setCustomerMobile } from "../redux/customer";
import { setScreenDetails } from "../redux/screens";
import { findMemberByPhone } from "../services/members";
import { SCREEN_FOUND, SCREEN_NOT_FOUND } from "../utils/const";
import UpdateRequired from "./UpdateRequired";

function DefaultHome() {
  const dispatch = useDispatch();
  const mobile = useSelector(getCustomerMobile);

  const searchHandler = () => {
    const item = `+63${mobile}`;

    findMemberByPhone(item).then((data) => {
      const [found] = data;
      if (found) {
        dispatch(setScreenDetails({ type: SCREEN_FOUND, data: found }));
      } else {
        dispatch(setScreenDetails({ type: SCREEN_NOT_FOUND, data: item }));
      }
    });
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex flex-column vertical-center justify-content-center">
            <div className="input-group my-4">
              <span className="input-group-text" id="country-code">
                +63
              </span>
              <input
                value={mobile}
                inputMode="numeric"
                onChange={({ currentTarget: input }) => {
                  if (!isNaN(input.value)) {
                    dispatch(setCustomerMobile(input.value));
                  }
                }}
                type="text"
                className="form-control form-control-lg"
                placeholder=""
                aria-label="Phone Number"
                aria-describedby="country-code"
              />
            </div>
            <div className="text-center">
              <Button
                variant="outline-secondary"
                size="lg"
                className="px-3 me-3"
                disabled={mobile.length === 0}
                onClick={() => dispatch(setCustomerMobile(""))}
              >
                Clear
              </Button>
              <Button
                variant="danger"
                size="lg"
                className="px-4"
                disabled={mobile.length < 10}
                onClick={searchHandler}
              >
                Search
              </Button>
            </div>
            <div className="text-muted pt-5">{/* <h4>Shortcuts</h4> */}</div>
            <MenuShortcuts />
            <UpdateRequired />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default DefaultHome;
