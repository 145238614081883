import React from "react";
import { Col, Row } from "react-bootstrap";

function AccountSummaryDetails({ type = "add", details }) {
  const css = "bg-white memberkarddottedborder"; //type === "claim" ? `alert-danger` : `alert-success`;
  // const css = type === "claim" ? `alert-danger` : `alert-success`;
  const { fullnumber, points, name } = details;
  return (
    <Row>
      <Col className="py-1">
        <div className={` ${css} `}>
          <h2 className="pt-4 d-flex align-items-center justify-content-center">
            {name}
          </h2>
          <h2 className="text-muted d-flex align-items-center justify-content-center">
            {fullnumber}
          </h2>
          <h1 className="text-dark d-flex align-items-center justify-content-center py-3 fw-bold">
            {points} Points
          </h1>
        </div>
      </Col>
    </Row>
  );
}

export default AccountSummaryDetails;
