import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Input from "../common/Input";
import {
  getCustomerAddress,
  getCustomerName,
  setCustomerAddress,
  setCustomerName,
} from "../redux/customer";
import {
  getScreenDetails,
  goBackScreen,
  setScreenDetails,
} from "../redux/screens";
import { getUserDetails } from "../redux/user";
import { addMemberToDB } from "../services/members";
import { addTransactionToDB } from "../services/transactions";
import { ACTIVITY_JOIN, SCREEN_CREATE_ACCOUNT_SUCCESS } from "../utils/const";

function CreateAccount() {
  const dispatch = useDispatch();
  const data = useSelector(getScreenDetails);
  const name = useSelector(getCustomerName);
  const address = useSelector(getCustomerAddress);
  const user = useSelector(getUserDetails);

  const goBack = () => {
    dispatch(goBackScreen());
  };

  const handleSaveAccount = async () => {
    //get all values and validate
    const date = new Date();
    const member = {
      fullnumber: data.data,
      countrycode: data.data.substring(0, 3),
      mobile: data.data.substring(3),
      name: name,
      address: address,
      joined: date.toString(),
      timestamp: date.getTime(),
      points: 0,
      //add company and company id
      company: user.company,
      companyid: user.companyid,
    };
    //add to members
    const id = await addMemberToDB(member);
    //history
    const transaction = {
      fullnumber: member.fullnumber,
      date: date.toString().substring(4),
      timestamp: date.getTime(),
      addedBy: user.name,
      addedById: user.uid,
      points: 0,
      total: 0,
      type: ACTIVITY_JOIN,
      operation: "+",
      memberid: id,
      company: user.company,
      companyid: user.companyid,
    };
    //add to transactions
    await addTransactionToDB(transaction);
    // const obj = [transaction, ...transactionRecord];
    // dispatch(setTransactionHistory(obj));
    // const newMembers = [...members, member];
    // dispatch(setMembers(newMembers));
    dispatch(
      setScreenDetails({
        type: SCREEN_CREATE_ACCOUNT_SUCCESS,
        data: member,
      })
    );
    //clear fields
    dispatch(setCustomerAddress(""));
    dispatch(setCustomerName(""));
  };

  return (
    <Container>
      <Row>
        <Col className="mt-5">
          <h1 className="text-danger fw-bold">Add Member</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-center my-3">
            <div className="input-group my-3">
              <span className="input-group-text" id="country-code">
                +63
              </span>
              <input
                type="text"
                disabled
                value={data.data.substring(3)}
                onChange={() => {}}
                className="form-control form-control-lg"
                aria-label="Phone Number"
                aria-describedby="country-code"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="mb-4">
          <Input
            name="name"
            label="Name"
            value={name}
            onChange={({ currentTarget: input }) => {
              dispatch(setCustomerName(input.value));
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="">
          <Input
            name="address"
            label="Address"
            placeholder="Optional"
            value={address}
            onChange={({ currentTarget: input }) => {
              dispatch(setCustomerAddress(input.value));
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="justify-content-end d-flex py-4">
          <Button
            variant="outline-secondary"
            className="px-3 me-4"
            size="lg"
            onClick={goBack}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            className="px-4"
            size="lg"
            disabled={name.length === 0}
            onClick={handleSaveAccount}
          >
            Save Member
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default CreateAccount;
