import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { db } from "./fire";

export async function getAllUserTransactionsByCompany(memberid, company) {
  try {
    const ref = collection(db, "transactions");
    const q = query(
      ref,
      where("memberid", "==", memberid),
      where("company", "==", company),
      orderBy("timestamp", "desc")
    );

    const querySnapshot = await getDocs(q);

    const list = [];
    querySnapshot.forEach((doc) => {
      list.push(doc.data());
    });
    return list;
  } catch (error) {
    console.error("Collection not yet created", error);
  }
  return [];
}

export async function addTransactionToDB(transaction) {
  await addDoc(collection(db, "transactions"), transaction);
}
