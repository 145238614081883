import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMembers, setMembers } from "../redux/members";
import DataTable from "react-data-table-component";
import { getAllCompanyMembers } from "../services/members";
import { getUserDetails } from "../redux/user";

function MembersList() {
  const dispatch = useDispatch();
  const list = useSelector(getMembers);
  const user = useSelector(getUserDetails);

  useEffect(() => {
    //get members list here
    getAllCompanyMembers(user.company).then((data) => {
      dispatch(setMembers(data));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => `${row.countrycode} ${row.mobile}`,
      sortable: true,
    },
    {
      name: "Points",
      selector: (row) => row.points,
      sortable: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
        paddingLeft: "0px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "16px",
      },
    },
    cells: {
      style: {
        fontSize: "16px",
        paddingLeft: "0px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  return (
    <div>
      <h1 className="text-danger pb-4 fw-bold">Members</h1>
      <DataTable
        columns={columns}
        data={list}
        pagination
        customStyles={customStyles}
      />
      {/* <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Phone</th>
            <th scope="col">Points</th>
          </tr>
        </thead>
        <tbody>
          {list.length > 0 &&
            list.map((i, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{i.name}</td>
                  <td>{i.fullnumber}</td>
                  <td>{i.points}</td>
                </tr>
              );
            })}
        </tbody>
      </table> */}
      {/* {list.length === 0 && (
        <p className="text-center py-4">No Records Found</p>
      )} */}
    </div>
  );
}

export default MembersList;
