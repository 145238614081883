import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import { useDispatch } from "react-redux";
import { setScreenDetails } from "../redux/screens";
import { SCREEN_LOGIN } from "../utils/const";

function Welcome() {
  const dispatch = useDispatch();
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowLogin(true), 2500);
    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = () => {
    //submit
    dispatch(setScreenDetails({ type: SCREEN_LOGIN, data: null }));
  };

  return (
    <Container className="py-4 text-center">
      <Row>
        <Col className="my-4">
          <CardMembershipIcon className="dialogicon text-danger" />
          <h3 className="py-2 fw-bold">Welcome to Member Kard</h3>
          <p className="pb-4 fs-2">Get Rewarded!</p>
          {showLogin ? (
            <Button
              size="lg"
              className="px-5"
              variant="danger"
              onClick={handleSubmit}
            >
              Login
            </Button>
          ) : (
            <div>
              <Spinner
                className="me-1"
                size="lg"
                animation="border"
                variant="danger"
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Welcome;
