import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import HomeButton from "../common/HomeButton";
import Controls from "../common/Controls";
import TransactionHistory from "./TransactionHistory";
import AccountSummaryDetails from "../common/AccountSummaryDetails";
import { getScreenDetails } from "../redux/screens";

function ScreenFound() {
  const found = useSelector(getScreenDetails);

  return (
    <Container id="top">
      <Row>
        <Col className="pt-4 pointer">
          <HomeButton />
        </Col>
      </Row>
      <Row>
        <Col className="pt-4">
          <AccountSummaryDetails details={found.data} />
        </Col>
      </Row>
      <Row>
        <Col className="my-4">
          <Controls details={found.data} />
        </Col>
      </Row>
      <Row>
        <Col className="my-4">
          <TransactionHistory details={found.data} />
        </Col>
      </Row>
    </Container>
  );
}

export default ScreenFound;
