export const SCREEN_WELCOME = "welcome";
export const SCREEN_DEFAULT = "default";
export const SCREEN_LOGIN = "login";
export const SCREEN_NOT_FOUND = "notfound";
export const SCREEN_FOUND = "found";
export const SCREEN_ALREADY_EXIST = "alreadyexist";
export const SCREEN_ADD_POINTS = "addpoints";
export const SCREEN_ADD_POINTS_SUCCESS = "addpointssuccess";
export const SCREEN_ADD_POINTS_SEARCH = "addpointssearch";
export const SCREEN_REDEEM_POINTS = "redeempoints";
export const SCREEN_REDEEM_POINTS_SEARCH = "redeempointssearch";
export const SCREEN_REDEEM_POINTS_SUCCESS = "redeempointssuccess";
export const SCREEN_CREATE_ACCOUNT = "createaccount";
export const SCREEN_CREATE_ACCOUNT_SUCCESS = "createaccountsuccess";
export const SCREEN_CREATE_ACCOUNT_SEARCH = "createaccountsearch";
export const SCREEN_VIEW_MEMBERS = "viewmembers";

export const ACTIVITY_JOIN = "Join";
export const ACTIVITY_ADDED = "Added";
export const ACTIVITY_REDEEM = "Claim";
