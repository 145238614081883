import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getScreenDetails, goBackScreen } from "../redux/screens";
import { ACTIVITY_REDEEM } from "../utils/const";
import Input from "../common/Input";
import AccountSummaryDetails from "../common/AccountSummaryDetails";
import ModalRedeemPointsSuccess from "./ModalRedeemPointsSuccess";
import {
  findMemberByPhone,
  updateMemberTotalPoints,
} from "../services/members";
import { getUserDetails } from "../redux/user";
import { addTransactionToDB } from "../services/transactions";

function RedeemPoints() {
  const dispatch = useDispatch();
  const user = useSelector(getUserDetails);
  const found = useSelector(getScreenDetails);
  const [points, setPoints] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [details, setDetails] = useState(null);

  const goBack = () => {
    dispatch(goBackScreen());
  };

  const handleSavePoints = () => {
    findMemberByPhone(found.data.fullnumber).then(async (data) => {
      const [result] = data;
      if (result) {
        //update
        const total = result.points - points;
        const updated = { ...result, points: total };
        //update member data
        await updateMemberTotalPoints(result.id, total);

        const date = new Date();
        const transaction = {
          fullnumber: found.data.fullnumber,
          date: date.toString().substring(4),
          timestamp: date.getTime(),
          addedBy: user.name,
          addedById: user.uid,
          points: points,
          total: total,
          type: ACTIVITY_REDEEM,
          operation: "-",
          memberid: result.id,
          company: user.company,
          companyid: user.companyid,
        };
        //add to transactions
        await addTransactionToDB(transaction);
        setShowModal(true);
        setDetails({ points: points, screendata: updated, data: found.data });
      }
    });
  };

  return (
    <Container>
      <Row>
        <Col className="mt-4">
          <h1 className="text-danger fw-bold">Claim Points</h1>
        </Col>
      </Row>
      <Row>
        <Col className="py-2">
          <AccountSummaryDetails type="claim" details={found.data} />
        </Col>
      </Row>
      <Row>
        <Col className="my-2">
          <Input
            name="points"
            label="Claim Points"
            value={points}
            placeholder={0}
            inputMode="numeric"
            onChange={({ currentTarget: input }) => {
              let value = parseInt(input.value);
              if (isNaN(value)) {
                value = "";
              }
              if (!isNaN(value) && value > found.data.points) {
                value = found.data.points;
              }
              setPoints(value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="justify-content-end d-flex py-4">
          <Button
            variant="outline-secondary"
            className="px-4 me-4"
            size="lg"
            onClick={goBack}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            className="px-4"
            size="lg"
            disabled={points === "" || points === 0}
            onClick={handleSavePoints}
          >
            Claim Points
          </Button>
        </Col>
      </Row>
      {details !== null && (
        <ModalRedeemPointsSuccess show={showModal} details={details} />
      )}
    </Container>
  );
}

export default RedeemPoints;
