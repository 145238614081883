import React from "react";
import DefaultHome from "./DefaultHome";
import NoRecordFound from "./NoRecordFound";
import {
  SCREEN_ADD_POINTS,
  SCREEN_ADD_POINTS_SEARCH,
  SCREEN_CREATE_ACCOUNT,
  SCREEN_CREATE_ACCOUNT_SEARCH,
  SCREEN_CREATE_ACCOUNT_SUCCESS,
  SCREEN_DEFAULT,
  SCREEN_FOUND,
  SCREEN_LOGIN,
  SCREEN_NOT_FOUND,
  SCREEN_REDEEM_POINTS,
  SCREEN_REDEEM_POINTS_SEARCH,
  SCREEN_VIEW_MEMBERS,
  SCREEN_WELCOME,
} from "../utils/const";
import CreateAccount from "./CreateAccount";
import CreateAccountSuccess from "./CreateAccountSuccess";
import AddPoints from "./AddPoints";
import ScreenFound from "./ScreenFound";
import RedeemPoints from "./RedeemPoints";
import ViewMembers from "./ViewMembers";
import LoginForm from "./LoginForm";
import CreateAccountSearch from "./CreateAccountSearch";
import AddPointsSearch from "./AddPointsSearch";
import RedeemPointsSearch from "./RedeemPointsSearch";
import Welcome from "./Welcome";

function Screens({ screen }) {
  const screens = {
    [SCREEN_WELCOME]: <Welcome />,
    [SCREEN_DEFAULT]: <DefaultHome />,
    [SCREEN_LOGIN]: <LoginForm />,
    [SCREEN_NOT_FOUND]: <NoRecordFound />,
    [SCREEN_FOUND]: <ScreenFound />,
    [SCREEN_ADD_POINTS]: <AddPoints />,
    [SCREEN_ADD_POINTS_SEARCH]: <AddPointsSearch />,
    [SCREEN_REDEEM_POINTS]: <RedeemPoints />,
    [SCREEN_REDEEM_POINTS_SEARCH]: <RedeemPointsSearch />,
    [SCREEN_CREATE_ACCOUNT]: <CreateAccount />,
    [SCREEN_CREATE_ACCOUNT_SEARCH]: <CreateAccountSearch />,
    [SCREEN_CREATE_ACCOUNT_SUCCESS]: <CreateAccountSuccess />,
    [SCREEN_VIEW_MEMBERS]: <ViewMembers />,
  };
  return screens[screen];
}

export default Screens;
