import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Header from "../common/Header";
import { getScreenDetails } from "../redux/screens";
import Screens from "../screens/Screens";

function ScreenRoute() {
  const screen = useSelector(getScreenDetails);
  return (
    <div>
      <Header />
      <Container className="mt-5" id="topscreen">
        <Row className="justify-content-center">
          <Col lg={8}>
            <Screens screen={screen.type} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ScreenRoute;
