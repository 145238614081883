import React from "react";
import { useDispatch } from "react-redux";
import {
  SCREEN_ADD_POINTS_SEARCH,
  SCREEN_CREATE_ACCOUNT_SEARCH,
  SCREEN_REDEEM_POINTS_SEARCH,
  SCREEN_VIEW_MEMBERS,
} from "../utils/const";
import { setScreenDetails } from "../redux/screens";
import { Col, Row } from "react-bootstrap";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";

function MenuShortcuts() {
  const dispatch = useDispatch();
  return (
    <Row className="">
      <Col xs={6} sm={4} md={3} className="my-2 text-center">
        <div
          className="py-4 menuicon"
          onClick={() => {
            dispatch(
              setScreenDetails({
                type: SCREEN_ADD_POINTS_SEARCH,
                data: null,
              })
            );
          }}
        >
          <div className="fw-bold pointer d-flex row">
            <div id="redicon">
              <AddCircleOutlineRoundedIcon fontSize="large" />
            </div>
            <div>Add Points</div>
          </div>
        </div>
      </Col>
      <Col xs={6} sm={4} md={3} className="my-2 text-center">
        <div
          className="py-4 menuicon"
          onClick={() => {
            dispatch(
              setScreenDetails({
                type: SCREEN_REDEEM_POINTS_SEARCH,
                data: null,
              })
            );
          }}
        >
          <div className="fw-bold pointer d-flex row text-truncate">
            <div id="redicon">
              <RemoveCircleOutlineRoundedIcon fontSize="large" />
            </div>
            <div className="">Claim Points</div>
          </div>
        </div>
      </Col>
      <Col xs={6} sm={4} md={3} className="my-2 text-center">
        <div
          className="py-4 menuicon"
          onClick={() => {
            dispatch(
              setScreenDetails({
                type: SCREEN_CREATE_ACCOUNT_SEARCH,
                data: null,
              })
            );
          }}
        >
          <div className="fw-bold pointer d-flex row">
            <div id="redicon">
              <PersonAddOutlinedIcon fontSize="large" />
            </div>
            <div>Add Member</div>
          </div>
        </div>
      </Col>
      <Col xs={6} sm={4} md={3} className="my-2 text-center">
        <div
          className="py-4 menuicon"
          onClick={() => {
            dispatch(
              setScreenDetails({
                type: SCREEN_VIEW_MEMBERS,
                data: null,
              })
            );
          }}
        >
          <div className="fw-bold pointer d-flex row">
            <div id="redicon">
              <PeopleOutlinedIcon fontSize="large" />
            </div>
            <div>View Members</div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default MenuShortcuts;
