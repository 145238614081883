import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME = "members";

const initialState = {
  members: [
    // {
    //   countrycode: "+63",
    //   mobile: "9068508096",
    //   fullnumber: "+639068508096",
    //   address: "Blk 103 Henderson Crescent, Singapore",
    //   name: "Germaine M. Caga",
    //   joined: "30 Aug 2022",
    //   points: 1588,
    // },
  ],
};

/*
    fullname: "",
    email: "",
    mobile: "",
    userid: "",
    created: 0,
    points: 0
*/

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setMembers: (state, action) => {
      state.members = action.payload;
    },
    resetMembers: () => initialState,
  },
});

function getSlice(state) {
  return state[SLICE_NAME];
}
//setters
export const { setMembers, resetMembers } = slice.actions;

//getters
export const getMembers = (state) => getSlice(state).members;

export default slice.reducer;
