import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import HomeButton from "../common/HomeButton";
import { getWebVersion } from "../utils/settings";
import MembersList from "./MembersList";

function ViewMembers() {
  return (
    <Container>
      <Row>
        <Col className="pt-4 pointer">
          <HomeButton />
        </Col>
      </Row>

      <Row>
        <Col className="my-3">
          <MembersList />
        </Col>
      </Row>
      <Row>
        <Col className="text-center my-3">
          <p className="text-muted">v{getWebVersion()}</p>
        </Col>
      </Row>
    </Container>
  );
}

export default ViewMembers;
