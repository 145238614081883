import {
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./fire";

export const findMemberByPhone = async (mobile) => {
  try {
    const ref = collection(db, "members");
    const q = query(ref, where("fullnumber", "==", mobile));

    const querySnapshot = await getDocs(q);

    const list = [];
    querySnapshot.forEach((doc) => {
      list.push(doc.data());
    });
    return list;
  } catch (error) {
    console.error("Collection not yet created", error);
  }
  return [];
};

export async function getAllCompanyMembers(company) {
  try {
    const ref = collection(db, "members");
    const q = query(
      ref,
      where("company", "==", company),
      orderBy("name", "asc")
    );

    const querySnapshot = await getDocs(q);

    const list = [];
    querySnapshot.forEach((doc) => {
      list.push(doc.data());
    });
    return list;
  } catch (error) {
    console.error("Collection not yet created", error);
  }
  return [];
}

export async function addMemberToDB(member) {
  const result = await addDoc(collection(db, "members"), member);
  const docRef = doc(db, "members", result.id);
  await updateDoc(docRef, {
    id: result.id,
  });
  return result.id;
}

export async function updateMemberTotalPoints(id, total) {
  const docRef = doc(db, "members", id);
  await updateDoc(docRef, {
    points: total,
  });
}
