import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getScreenDetails,
  resetDefaultScreenDetails,
  setScreenDetails,
} from "../redux/screens";
import { SCREEN_ADD_POINTS } from "../utils/const";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

function CreateAccountSuccess() {
  const dispatch = useDispatch();
  const found = useSelector(getScreenDetails);
  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex flex-column mt-5 justify-content-center">
            <div className="memberkarddottedborder text-center p-4 mb-4">
              <CheckCircleOutlineRoundedIcon className="dialogicon text-success mb-4" />
              <h2>{found.data.fullnumber}</h2>
              <h2>successfully added!</h2>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center py-2">
          <Button
            variant="outline-secondary"
            size="lg"
            className="me-4"
            onClick={() => dispatch(resetDefaultScreenDetails())}
          >
            Home
          </Button>
          <Button
            variant="danger"
            size="lg"
            className="px-4"
            onClick={() =>
              dispatch(
                setScreenDetails({
                  type: SCREEN_ADD_POINTS,
                  data: found.data,
                })
              )
            }
          >
            Add Points
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default CreateAccountSuccess;
