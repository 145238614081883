import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME = "transactionhistory";

const initialState = {
  transactionhistory: [
    {
      fullnumber: "+639068508096",
      date: "Dec 04 2022 19:00:00",
      by: "Gil",
      points: 688,
      total: 1588,
      type: "Added",
      operation: "+",
    },
    {
      fullnumber: "+639068508096",
      date: "Nov 29 2022 17:05:23",
      by: "Gil",
      points: 500,
      total: 900,
      type: "Added",
      operation: "+",
    },
    {
      fullnumber: "+639068508096",
      date: "Oct 03 2022 12:35:00",
      by: "Gil",
      points: 400,
      total: 400,
      type: "Added",
      operation: "+",
    },
    {
      fullnumber: "+639068508096",
      date: "Sep 01 2022 19:24:83",
      by: "Gil",
      points: 0,
      total: 0,
      type: "Join",
      operation: "+",
    },
  ],
};

/*
    fullname: "",
    email: "",
    mobile: "",
    userid: "",
    created: 0,
    points: 0
*/

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setTransactionHistory: (state, action) => {
      state.transactionhistory = action.payload;
    },
    resetTransactionHistory: () => initialState,
  },
});

function getSlice(state) {
  return state[SLICE_NAME];
}
//setters
export const { setTransactionHistory, resetTransactionHistory } = slice.actions;

//getters
export const getTransactionHistory = (state) =>
  getSlice(state).transactionhistory;

export default slice.reducer;
