import React, { useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import Input from "../common/Input";
import { isValidEmail } from "../utils/validate";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../services/fire";
import { useDispatch } from "react-redux";
import { goBackScreen } from "../redux/screens";

function LoginForm() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [loading, setLoading] = useState(false);

  const goBack = () => {
    dispatch(goBackScreen());
  };

  const handleBlur = () => {
    const isEmailOk = isValidEmail(email);
    if (!isEmailOk) {
      setEmailErr("Invalid Email");
    } else {
      setEmailErr("");
    }
  };

  const handleSubmit = async () => {
    const isEmailOk = isValidEmail(email);
    if (!isEmailOk) {
      setEmailErr("Invalid Email");
    }
    if (password.length > 0 && isEmailOk) {
      setEmailErr("");
      setPasswordErr("");
      try {
        setLoading(true);
        await signInWithEmailAndPassword(auth, email, password);
      } catch (e) {
        setPasswordErr("Incorrect email or password");
      }
      setLoading(false);
    }
  };

  return (
    <Container className="py-4">
      <Row>
        <Col className="my-4">
          <h1>Login to Member Kard</h1>
        </Col>
      </Row>

      <Row>
        <Col className="mb-4">
          <Input
            name="email"
            label="Email"
            value={email}
            error={emailErr}
            onBlur={handleBlur}
            onChange={({ currentTarget: input }) => {
              setEmail(input.value.toLowerCase());
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="">
          <Input
            type="password"
            name="password"
            label="Password"
            value={password}
            error={passwordErr}
            onChange={({ currentTarget: input }) => {
              setPassword(input.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="justify-content-end d-flex py-4">
          <Button
            variant="outline-secondary"
            className="px-3 me-4"
            size="lg"
            onClick={goBack}
          >
            Back
          </Button>
          <Button
            variant="danger"
            className="px-4"
            size="lg"
            disabled={loading || email.length === 0 || password.length === 0}
            onClick={handleSubmit}
          >
            {loading ? (
              <div className="d-flex align-items-center">
                <Spinner
                  className="me-1"
                  size="sm"
                  animation="border"
                  variant="light"
                />
                Logging...
              </div>
            ) : (
              "Login Now"
            )}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginForm;
