import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setScreenDetails } from "../redux/screens";
import { SCREEN_FOUND } from "../utils/const";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

function ModalAddPointsSuccess({ show, details }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <Container>
      <Row>
        <Col>
          <Modal
            show={showModal}
            // onHide={() => setShowModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="text-success fw-bold"
              >
                Added Successfully
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex flex-column mt-4 justify-content-center">
                <div className="text-center mb-4">
                  <CheckCircleOutlineRoundedIcon className="dialogicon text-success mb-3" />
                  <h1 className="text-success fw-bold">
                    +{details.points} points
                  </h1>
                  <h2>{details.data.name}</h2>
                  <h2 className="text-muted">{details.data.fullnumber}</h2>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setShowModal(false);
                  dispatch(
                    setScreenDetails({
                      type: SCREEN_FOUND,
                      data: details.screendata,
                    })
                  );
                }}
              >
                Done
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}

export default ModalAddPointsSuccess;
