import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setScreenDetails } from "../redux/screens";
import { SCREEN_ADD_POINTS, SCREEN_REDEEM_POINTS } from "../utils/const";

function Controls({ details }) {
  const dispatch = useDispatch();
  return (
    <div className="text-center">
      <Button
        variant="outline-danger"
        size="lg"
        className="me-3"
        disabled={details.points === 0}
        onClick={() =>
          dispatch(
            setScreenDetails({ type: SCREEN_REDEEM_POINTS, data: details })
          )
        }
      >
        Claim Points
      </Button>
      <Button
        variant="danger"
        size="lg"
        className=""
        onClick={() =>
          dispatch(setScreenDetails({ type: SCREEN_ADD_POINTS, data: details }))
        }
      >
        Add Points
      </Button>
    </div>
  );
}

export default Controls;
