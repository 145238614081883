import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getScreenDetails,
  resetDefaultScreenDetails,
  setScreenDetails,
} from "../redux/screens";
import { SCREEN_CREATE_ACCOUNT } from "../utils/const";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

function NoRecordFound() {
  const dispatch = useDispatch();
  const data = useSelector(getScreenDetails);
  return (
    <Container>
      <Row>
        <Col className="text-center memberkarddottedborder p-4 mt-5 mb-4 mx-3">
          <WarningAmberRoundedIcon className="text-danger dialogicon mb-4" />
          <h2 className="">{data.data}</h2>
          <h2>not found!</h2>
        </Col>
      </Row>
      <Row>
        <Col className="text-center py-2 px-0">
          <Button
            variant="outline-secondary"
            size="lg"
            className="me-3"
            onClick={() => dispatch(resetDefaultScreenDetails())}
          >
            Home
          </Button>
          <Button
            variant="danger"
            size="lg"
            className="px-3"
            onClick={() =>
              dispatch(
                setScreenDetails({
                  type: SCREEN_CREATE_ACCOUNT,
                  data: data.data,
                })
              )
            }
          >
            Add Member
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default NoRecordFound;
