import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME = "user";

const initialState = {
  user: null,
};

/*
    fullname: "",
    email: "",
    mobile: "",
    userid: "",
    created: 0,
    points: 0
*/

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.user = action.payload;
    },
    resetUserDetails: (state) => initialState,
  },
});

function getSlice(state) {
  return state[SLICE_NAME];
}
//setters
export const { setUserDetails, resetUserDetails } = slice.actions;

//getters
export const getUserDetails = (state) => getSlice(state).user;

export default slice.reducer;
