import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME = "customer";

const initialState = {
  countrycode: "+63",
  name: "",
  mobile: "",
  address: "",
  total: 0,
  history: [],
};

/*
    name: "",
    email: "",
    mobile: "",
    userid: "",
    created: 0,
    points: 0
*/

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setCustomerName: (state, action) => {
      state.name = action.payload;
    },
    setCustomerMobile: (state, action) => {
      state.mobile = action.payload;
    },
    setCustomerAddress: (state, action) => {
      state.address = action.payload;
    },
    setCustomerPoints: (state, action) => {
      state.total = action.payload;
    },
    setCustomerHistory: (state, action) => {
      state.history = action.payload;
    },
    resetCustomer: () => initialState,
  },
});

function getSlice(state) {
  return state[SLICE_NAME];
}
//setters
export const {
  setCustomerName,
  setCustomerMobile,
  setCustomerAddress,
  setCustomerHistory,
  setCustomerPoints,
  resetCustomer,
} = slice.actions;

//getters
export const getCustomerName = (state) => getSlice(state).name;
export const getCustomerMobile = (state) => getSlice(state).mobile;
export const getCustomerAddress = (state) => getSlice(state).address;
export const getCustomerPoints = (state) => getSlice(state).total;
export const getCustomerHistory = (state) => getSlice(state).history;

export default slice.reducer;
