import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatTime } from "../utils/date";
import {
  getTransactionHistory,
  setTransactionHistory,
} from "../redux/transactionhistory";
import { ACTIVITY_ADDED, ACTIVITY_JOIN, ACTIVITY_REDEEM } from "../utils/const";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import { getUserDetails } from "../redux/user";
import { getAllUserTransactionsByCompany } from "../services/transactions";

function TransactionHistory({ details }) {
  const dispatch = useDispatch();
  const history = useSelector(getTransactionHistory);
  const [showMore, setShowMore] = useState(false);
  const user = useSelector(getUserDetails);

  return (
    <div>
      <div className={`d-flex justify-content-end align-items-center`}>
        <div
          className="pb-4 align-items-center d-flex fs-6 pointer"
          onClick={() => {
            getAllUserTransactionsByCompany(details.id, user.company).then(
              (data) => {
                dispatch(setTransactionHistory(data));
                setShowMore((prev) => !prev);
              }
            );
          }}
        >
          {!showMore && (
            <>
              Show History
              <ReceiptLongRoundedIcon className="ms-1 mediumfont" />
            </>
          )}
        </div>
      </div>
      <div className={!showMore ? "d-none" : "d-block"}>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Date</th>
              <th scope="col">By</th>
              <th scope="col">Points</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {history.length > 0 &&
              history
                .filter((i) => i.fullnumber === details.fullnumber)
                .map((i, index, { length }) => {
                  const textDate = i.date.split(" GMT+")[0].slice(0, -3);
                  const ago = formatTime(new Date(i.date));
                  let css = "fw-bold ";
                  if (i.type === ACTIVITY_REDEEM) {
                    css += "text-danger";
                  } else if (i.type === ACTIVITY_ADDED) {
                    css += "text-success";
                  }
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        {textDate} <br />
                        <small className="text-muted">
                          {index + 1 === length ? `${ACTIVITY_JOIN}ed` : ago}
                        </small>
                      </td>
                      <td>{i.addedBy}</td>
                      <td className={css}>{i.points}</td>
                      <td className="fw-bold">{i.total}</td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        {history.length === 0 && (
          <p className="text-center py-4">No Records Found</p>
        )}
      </div>
    </div>
  );
}

export default TransactionHistory;
