import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "./notfound/page/NotFound";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./services/fire";
import { useDispatch } from "react-redux";
import { resetUserDetails, setUserDetails } from "./redux/user";
import { resetScreenDetails, setScreenDetails } from "./redux/screens";
import { SCREEN_DEFAULT } from "./utils/const";
import ScreenRoute from "./screens/ScreenRoute";
import { getUserFromDB } from "./services/user";

function App() {
  const dispatch = useDispatch();

  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) {
      getUserFromDB(currentUser.uid).then((data) => {
        const userObj = {
          name: currentUser.displayName || data.name,
          email: currentUser.email || data.email,
          mobile: currentUser.phoneNumber || data.mobile,
          photo: currentUser.photoURL || data.photo,
          uid: currentUser.uid,
          company: data.company,
          companyid: data.companyid,
        };
        dispatch(setUserDetails(userObj));
        dispatch(
          setScreenDetails({
            type: SCREEN_DEFAULT,
            data: userObj,
          })
        );
      });
    } else {
      dispatch(resetUserDetails());
      dispatch(resetScreenDetails());
    }
  });

  return (
    <Routes>
      {/* <Route element={<PrivateRoutes />}> */}
      <Route element={<ScreenRoute />} path="/" exact />
      {/* <Route element={<HomePage />} path="/" exact /> */}
      {/* </Route> */}
      {/* <Route element={<Login />} path="/login" /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
