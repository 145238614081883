import { createSlice } from "@reduxjs/toolkit";
import { SCREEN_DEFAULT, SCREEN_WELCOME } from "../../utils/const";

const SLICE_NAME = "screens";

const initialState = {
  screens: { type: SCREEN_WELCOME, data: null },
  stack: [],
};

const initialDefaultState = {
  screens: { type: SCREEN_DEFAULT, data: null },
  stack: [],
};

/*
   type: 
   data:
*/

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setScreenDetails: (state, action) => {
      state.stack.push(state.screens);
      state.screens = action.payload;
    },
    goBackScreen: (state) => {
      const value = state.stack.pop();
      state.screens = value;
    },
    resetScreenDetails: () => initialState,
    resetDefaultScreenDetails: () => initialDefaultState,
  },
});

function getSlice(state) {
  return state[SLICE_NAME];
}
//setters
export const {
  setScreenDetails,
  goBackScreen,
  resetScreenDetails,
  resetDefaultScreenDetails,
} = slice.actions;

//getters
export const getScreenDetails = (state) => getSlice(state).screens;

export default slice.reducer;
