import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerMobile, setCustomerMobile } from "../redux/customer";
import { getMembers } from "../redux/members";
import { resetDefaultScreenDetails, setScreenDetails } from "../redux/screens";
import { SCREEN_CREATE_ACCOUNT, SCREEN_FOUND } from "../utils/const";

function CreateAccountSearch() {
  const dispatch = useDispatch();
  const mobile = useSelector(getCustomerMobile);
  const members = useSelector(getMembers);
  const searchHandler = () => {
    const item = `+63${mobile}`;
    const [found] = members.filter((i) => i.fullnumber === item);
    if (found) {
      //get from member
      dispatch(setScreenDetails({ type: SCREEN_FOUND, data: found }));
    } else {
      dispatch(setScreenDetails({ type: SCREEN_CREATE_ACCOUNT, data: item }));
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex flex-column vertical-center justify-content-center">
            <div>
              <h1 className="text-danger fw-bold pb-4">Add Member</h1>
            </div>
            <div>
              <h2>What's the phone number?</h2>
            </div>
            <div className="input-group my-4">
              <span className="input-group-text" id="country-code">
                +63
              </span>
              <input
                value={mobile}
                inputMode="numeric"
                onChange={({ currentTarget: input }) => {
                  if (!isNaN(input.value)) {
                    dispatch(setCustomerMobile(input.value));
                  }
                }}
                type="text"
                className="form-control form-control-lg"
                placeholder=""
                aria-label="Phone Number"
                aria-describedby="country-code"
              />
            </div>
            <div className="text-center">
              <Button
                variant="outline-secondary"
                size="lg"
                className="me-4"
                onClick={() => dispatch(resetDefaultScreenDetails())}
              >
                Home
              </Button>
              <Button
                variant="danger"
                size="lg"
                className="px-4"
                disabled={mobile.length < 10}
                onClick={searchHandler}
              >
                Continue
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default CreateAccountSearch;
