import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { getVersion } from "../services/version";
import { getWebVersion } from "../utils/settings";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";

function UpdateRequired() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    //get version here
    getVersion().then((data) => {
      if (data.version !== getWebVersion()) {
        setShowModal(true);
      }
    });
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <Modal
            show={showModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="text-success fw-bold"
              >
                Update Required
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex flex-column mt-4 justify-content-center">
                <div className="text-center mb-4">
                  <NewReleasesRoundedIcon className="dialogicon text-success mb-3" />
                  <h1 className="text-success fw-bold">
                    New Version Available!
                  </h1>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="success"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Get New Version
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}

export default UpdateRequired;
